import styled from 'styled-components';

const VideoAndContentStyled = styled.div`
    width: 75%;
    height: auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    padding: 0 1rem;
    margin: 2rem;
    margin-bottom: 8rem;

    .video {
    border: 3px solid grey;
    margin-bottom: 20px;
    border-radius: 10px;
    padding: 5px;
    }

    p {
    font-size: 2rem;
    font-weight: bold;
    margin: 0;
    padding: 0 1rem;
    color: #aa8383;
    font-family: "Josefin Sans";
    }

    @media screen and (max-width: 500px), (min-width: 500px) {
    flex-wrap: wrap;

    p {
        padding: 0;
        font-size: 1.5rem;
    }
    }
`
export default VideoAndContentStyled;