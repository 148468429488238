import React from 'react'
import HomeComposition from "../compositions/Home"

export default function Home(objectOfArrayOfPages) {
    const arrayOfPages = objectOfArrayOfPages.data

    return (
        <HomeComposition data={arrayOfPages} />
    )
}
