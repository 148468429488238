import React from 'react'
import ImagesStyled from './ImagesStyled'
import { useQuery } from "graphql-hooks";
import { MY_QUERY } from "../../data/myQuery"
import { Image } from 'react-datocms';

export default function ImagesCard() {

    const { loading, error, data } = useQuery(MY_QUERY, {
        variables: {
            limit: 10
        }
    });

    if (loading) return "Loading...";
    if (error) return "Something Bad Happened";

    const arrayOfPages = data.allPages[1].sections[0].gridColumns
    /* const arrayOfImages = arrayOfPages[0].sections[1].gridColumns */
    /* const img = arrayOfPages.data[1] */

    return (
        <ImagesStyled>
            {arrayOfPages.map((images) => {
                const arrayOfImages = images.content.blocks
                return arrayOfImages.map((image, id) => {
                    const imgArray = image.images
                    return <div key={id} className='container'>{imgArray.map((img, id) => {
                        const respImg = img.responsiveImage
                        return <div key={id} className='img'><Image className='image' data={respImg} /></div>
                    })}</div>
                })

            })}
        </ImagesStyled>
    )
}
