import React from "react";
import { Route, Routes } from "react-router-dom";
import LayoutStyled from "./LayoutStyled";
import Home from "../../containers/Home";
import Merchandise from "../../containers/Merchandise";
import Entertainment from "../../containers/Entertainment";
import Maintenance from "../../containers/Maintenance";
import Contact from "../../components/Contact";
import TermsAndConditions from "../../components/TermsAndConditions";

export default function Layout(data) {
    const arrayOfData = data.data
    const arrayOfPages = arrayOfData.allPages

    return (
        <LayoutStyled>
            <Routes>
                <Route exact path="/" element={<Home data={arrayOfPages} />} />
                <Route path="/hem" element={<Home data={arrayOfPages} />} />
                <Route path="/galleri" element={<Merchandise data={arrayOfPages} />} />
                <Route path="/foretaget" element={<Entertainment />} />
                <Route path="/kontakt" element={<Maintenance data={arrayOfPages} />} />
                {/* <Route path="/contact" element={<Contact />} /> */}
                <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                {/* <Route path="/products" component={ProductsView} />
                <Route path="/product/:id" component={DetailView} />
                <Route path="/order" component={OrderView} />
                <Route path="/confirmation" component={ConfirmationView} />
                <Route path="/cancel" component={CancelView} /> */}
            </Routes>
        </LayoutStyled>
    );
}

