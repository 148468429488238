import styled from 'styled-components';

const TermsAndConditionsStyled = styled.div`
width: 100%;
height: 100vh;
margin-top: 15rem;
display: flex;
justify-content: center;
color: white;

@media screen and (max-width: 500px) {
        margin-top: 20rem;
}
`
export default TermsAndConditionsStyled;