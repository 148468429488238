import React from 'react'
import MerchandiseStyled from './MerchandiseStyled';
import ImagesCard from '../../components/ImagesCard';
import { Image } from 'react-datocms';
import Images from '../../components/Images'

export default function Merchandise(objectOfArrayOfPages) {
    const arrayOfPages = objectOfArrayOfPages.data
    /* const img = arrayOfPages.data[1].sections[0].gridColumns[0].content.blocks
    console.log(img) */
    return (
        <MerchandiseStyled>
            {/* <ImagesCard /> */}
            <Images />
        </MerchandiseStyled>
    )
}
