import React from "react";
import Navbar from "./compositions/Navbar"
import Layout from "./compositions/Layout"
import Footer from "./compositions/Footer"
import { useQuery } from "graphql-hooks";
import { MY_QUERY } from "./data/myQuery"
import "./App.css";

function App() {
  const { loading, error, data } = useQuery(MY_QUERY, {
    variables: {
      limit: 10
    }
  });

  if (loading) return "Loading...";
  if (error) return "Something Bad Happened";

  return (
    <div className="App">
      <Navbar data={data} />
      <Layout data={data} />
      <Footer data={data} />
    </div>
  );
}

export default App;
