import styled from 'styled-components';

const MaintenanceStyled = styled.div`
width: 100%;
height: auto;
display: flex;
flex-wrap: wrap;
background-color: #1d1b1b;
color: white;
font-family: 'Josefin Sans';
font-size: larger;

.container {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 6rem;
    margin-top: 3rem;
}

.infoContainer{
    display: flex;
    width: auto;
    align-items: end;
    justify-content: center;
    height: auto;
    padding: 2rem;
}

.image {
padding: 5px;
border-radius: 10px;
border: 3px solid;
}

.icons {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
   /*  margin-top: 1rem;
    padding-top: 0.5rem; */
}

.icon {
    /* margin-right: 8px; */
    margin-top: 10px;
}

.content {
    display: flex;
    flex-direction: column;
}

.contact {
    padding-left: 1rem;
}

.info {
    padding-bottom: 3rem;
    padding-right: 2.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mail {
    display: flex;
    margin-left: 0.5rem;
}

h2 {
    font-size: 2rem;
}

p {
    margin-top: 0.5rem;
}

a {
font-weight: bold;
text-decoration: none;
color: white;
background-image: linear-gradient(
    to right,
    #54b3d6,
    #54b3d6 50%,
    #fff 50%
);
background-size: 200% 100%;
background-position: -100%;
display: inline-block;
margin-left: 10px;
position: relative;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
transition: all 0.3s ease-in-out;
}

a:before {
content: '';
background: #bdbaba;
display: block;
position: absolute;
bottom: -3px;
left: 0;
width: 0;
height: 3px;
transition: all 0.3s ease-in-out;
}

a:hover {
background-position: 0;
}

a:hover::before {
width:100%;
}


.img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
`
export default MaintenanceStyled;