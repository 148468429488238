import styled from 'styled-components';

const MerchandiseStyled = styled.div`
width: 100%;
height: auto;
display: flex;
flex-wrap: wrap;
justify-content: space-between;
align-items: center;
flex-direction: column;
margin-top: 17rem;
padding-bottom: 10rem;

/* img {
    object-fit: cover;
    width: 100%;
    height: 100%;
} */

@media screen and (max-width: 500px), (min-width: 500px)  {
        /* margin-top: 20rem; */
}
@media screen and (min-width: 500px)  {
        /* margin-top: 30rem; */
}
`
export default MerchandiseStyled;