import React from 'react'
import VideoAndContentStyled from './VideoAndContentStyled'
import { useQuery } from "graphql-hooks";
import { MY_QUERY } from "../../data/myQuery"
import ReactPlayer from "react-player";
import { StructuredText } from 'react-datocms';

export default function VideoAndContent() {

    const { loading, error, data } = useQuery(MY_QUERY, {
        variables: {
            limit: 10
        }
    });

    if (loading) return "Loading...";
    if (error) return "Something Bad Happened";

    const thirdSectionOfFirstPage = data.allPages[0].sections[3]
    const video = data.allUploads[0].video.mp4Url
    /* const video = thirdSectionOfFirstPage.gridColumns[0].content.blocks[0].externalVideo.url */
    const contentTxt = thirdSectionOfFirstPage.gridColumns[1].content/* .value.document.children[0].children[0].value */

    return (
        <VideoAndContentStyled>
            <div className='video'>
                <ReactPlayer url={video} width="100%" height="100%" controls={true} />
            </div>
            <StructuredText data={contentTxt} />
        </VideoAndContentStyled>
    )
}
