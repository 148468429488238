import React from 'react'
import ListCardStyled from "./ListCardStyled"
import { useQuery } from "graphql-hooks";
import { StructuredText } from "react-datocms";
import { MY_QUERY } from "../../data/myQuery"
import ButtonMailto from '../ButtonMailto';

export default function ListCard() {

  const { loading, error, data } = useQuery(MY_QUERY, {
    variables: {
      limit: 10
    }
  });

  if (loading) return "Loading...";
  if (error) return "Something Bad Happened";

  const list = data.allPages[0].sections[4].gridColumns

  return (
    <ListCardStyled>
      {list.map((content, index) => {
        if (content.id === "121885788") {
          return <div className="text" key={content.id}><StructuredText data={content.content} />
            <div className="mail" ><ButtonMailto label="sweets86_@hotmail.com" mailto="mailto:sweets86_@hotmail.com" /></div>
          </div>
        } /* else {
          return <div className='list' key={index}><StructuredText data={content.content} /></div>
        } */
      })}
    </ListCardStyled >

  )
}
