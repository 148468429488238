import styled from 'styled-components';

const FooterStyled = styled.div`
background-color: black;
color: white;
display: flex;
flex-wrap: wrap;
justify-content: space-between;
/* flex-direction: row;
align-items: center; */
padding: 6rem;
padding-right: 12rem;


height: auto;
/* text-transform: lowercase; */
/* font-size: 1.5rem; */
font-family: "Josefin Sans";
/* font-size: 1.5rem; */

a {
text-decoration: none;
color: white;
}

p {
  margin-left: 0.5rem;
}


h4 {
    margin-left: 0.5rem;
}

.content {

}

.contact {
  margin-bottom: 3px;
}

.iconDiv{
  display: flex;
  align-items: center;
/*   flex-direction: column; */
  justify-content: space-between;
  width: 10rem;
  height: 10rem;
}

.icons {
  background-color: aliceblue;
  border-radius: 5px;
  width: 2rem;
}

.copy{
  display: flex;
  align-items: end;
  margin-top: 1rem;
  margin-left: 0.5rem;
}

span {
  display: flex;
  align-items: center;
  /* font-size: 1rem; */
}

.img {
  width: 1rem;
  height: 1rem;
  object-fit: contain;
}

@media screen and (max-width: 500px) {
flex-direction: column;
}
`
export default FooterStyled;
