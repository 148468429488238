import React from 'react'
import EntertainmentStyled from './EntertainmentStyled'
import VideoAndContent from '../../components/VideoAndContent'
import ListCard from '../../components/ListCard'

export default function Entertainment() {
    return (
        <EntertainmentStyled>
            <VideoAndContent />
            <ListCard />
        </EntertainmentStyled>
    )
}
