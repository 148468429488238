import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Image } from 'react-datocms';
import NavbarStyled from './NavbarStyled'
import CustomLink from '../../components/CustomLink';
import Burger from '../../components/Burger';

export default function Navbar(data) {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        window.addEventListener("resize", () => setOpenBurger(window.innerWidth))
    }, []);

    const [openBurger, setOpenBurger] = useState(window.innerWidth)
    const breakpoint = 500;

    const arrayOfPages = data.data.allPages
    const arrayOfItems = data.data.navbar.navbarItems[0]
    const icon = arrayOfItems.image.responsiveImage
    const title = arrayOfItems.labelText
    return (

        <NavbarStyled>
            <div className="navbar">
                <div className="icon"><Image data={icon} /></div>
                <div className="title"><Link to="/">{title}</Link></div>
            </div>
            {openBurger < breakpoint ? (
                <Burger open title={arrayOfPages} />
            ) : <ul>
                {arrayOfPages.map((page) => {
                    return <CustomLink key={page.id} to={page.slug}>{page.title}</CustomLink>
                })}
            </ul>}
        </NavbarStyled>
    )
}


