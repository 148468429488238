import React from "react";
import styled from "styled-components";
import CustomLink from "../CustomLink";

const DropMenuStyled = styled.div`
  /* background-color: black;
  color: white; */
  position: absolute;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
  bottom: 0;
  /* right: 0; */
  /* height: 34vh; */
 /*  width: 200px;
  padding-top: 3.5em; */
  transition: transform 0.3s ease-in-out;
  /* border-radius: 10px; */
  width: 100%;
  height: 35%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-bottom: 2rem;;
  margin-bottom: 1.5rem;
  /* align-items: center; */

    /* li {
      padding-bottom: 0px;
      height: 0.5em;
      width: 2em;
    }

    ul {
      padding-left: 0;
      padding-top: 0;
      margin-top: 0;
    } */

  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    padding-top: 5px;
  }

  li {
    padding: 10px 11px;
    display: inline-block;
    color: white;
    position: relative;
  }

  p {
    margin: 0;
    width: 4em;
  }

  /* @media screen and (max-width: 400px) {
    width: 86px;
    display: flex;
    align-items: center;

    li {
      padding-bottom: 0px;
      height: 0.5em;
      width: 2em;
    }

    ul {
      padding-left: 0;
      padding-top: 0;
      margin-top: 0;
    }
  } */
`;

/* interface Props {
  open: boolean;
} */

export default function DropMenu(objectOfTitle, open) {
  const arrayOfTitle = objectOfTitle.title
  return (
    <DropMenuStyled open={open}>
      {arrayOfTitle.map((title) => {
        return <CustomLink key={title.id} to={title.slug}>{title.title}</CustomLink>
      })}
    </DropMenuStyled >
  );
}
