import styled from 'styled-components';

const ImagesStyled = styled.div`
    width: auto;
    height: auto;
    /* display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center; */
    /* margin: 2rem; */
    padding: 2rem;
    padding-bottom: 10rem;

    .container {
        width: 100%;
        padding: 0.5rem;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-evenly;
    }

    .img {
        width: auto;
        padding: 0.5rem;
        border-radius: 10px;
        /* height: auto;
        border-radius: 25%; */
    }

    .image {
        border-radius: 10px;
    }

/* @media screen and (max-width: 500px) {
        flex-wrap: wrap;

        div {
            margin-bottom: 2rem;
        }
}

@media screen and (min-width: 500px)  {
    margin: 0;
    width: 100%;
    div {
        padding: 0 1rem;
    }
} */

`
export default ImagesStyled;