import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import { useMatch, useResolvedPath } from 'react-router-dom'

export default function CustomLink({ to, children, ...props }) {
    const resolvedPath = useResolvedPath(to)
    const isActive = useMatch({ path: resolvedPath.pathname, end: true })
    return (
        <li onClick={() => {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }}
        >
            <Link className={isActive ? 'active' : ''} to={to}{...props}>{children}</Link>
        </li>
    )
}