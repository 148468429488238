import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const ButtonMailtoStyled = styled.div`
    .mail {
        margin: 0;
        margin-left: 0.5rem;
        margin-bottom: 6px;
        text-decoration: none;
        color: white;
        /* font-size: 20px; */
        font-family: "Josefin Sans";
        font-weight: bold;
    }
    
`

const ButtonMailto = ({ mailto, label }) => {
    return (
        <ButtonMailtoStyled>
            <Link className="mail"
                to='#'
                onClick={(e) => {
                    window.location.href = mailto;
                    e.preventDefault();
                }}
            >
                {label}
            </Link>
        </ButtonMailtoStyled>
    );
};

export default ButtonMailto;