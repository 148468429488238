

export const MY_QUERY = `query MyQuery {
  navbar {
    navbarItems {
      labelText
      image {
        responsiveImage(imgixParams: { fit: crop, w: 300, h: 300, auto: format }){
          srcSet
          webpSrcSet
          sizes
          src
          width
          height
          aspectRatio
          alt
          title
          base64
        }
      }
      destination {
        slug
      }
    }
  }
  allUploads {
    video {
      duration
      framerate
      mp4Url
    }
  }
  allPages {
    slug
    title
    id
    backgroundImage {
      responsiveImage(imgixParams: { fit: crop, w: 1600, h: 400, auto: format }){
        srcSet
        webpSrcSet
        sizes
        src
        width
        height
        aspectRatio
        alt
        title
        base64
      }
    }
    footer {
      gridColumns {
        id
        content {
          value
          blocks {
            ... on ImageInlineRecord {
              id
              link {
                value
              }
              image {
                responsiveImage(imgixParams: { fit: crop, w: 50, h: 50, auto: format }) {
                  srcSet
                  webpSrcSet
                  sizes
                  src
                  width
                  height
                  aspectRatio
                  alt
                  title
                  base64
                }
              }
            }
            ... on ImagesInlineRecord {
              id
              images {
                responsiveImage(imgixParams: { fit: crop, w: 200, h: 200, auto: format }) {
                  width
                  webpSrcSet
                  title
                  srcSet
                  src
                  sizes
                  height
                  bgColor
                  base64
                  aspectRatio
                  alt
                }
              }
            }
          }
        }
      }
    }
    sections {
      id
      gridColumns {
        id
        desktopWidth
        mobileWidth
        tabletWidth
        content {
          value
          links
          blocks {
            __typename
            ... on ImageInlineRecord {
              id
              image {
                responsiveImage(imgixParams: { fit: crop, w: 300, h: 300, auto: format }) {
                  srcSet
                  webpSrcSet
                  sizes
                  src
                  width
                  height
                  aspectRatio
                  alt
                  title
                  base64
                }
                id
              }
            }
            ... on ImagesInlineRecord {
              id
              images {
                responsiveImage(imgixParams: { fit: crop, w: 300, h: 300, auto: format }) {
                  width
                  webpSrcSet
                  title
                  srcSet
                  src
                  sizes
                  height
                  bgColor
                  base64
                  aspectRatio
                  alt
                }
                id
              }
            }
            ... on VideoInlineRecord {
              id
              externalVideo {
                height
                provider
                providerUid
                thumbnailUrl
                title
                url
                width
              }
            }
            ... on LinkInlineRecord {
              linkText {
                value
              }
              link
              id
            }
            ... on IconsInlineRecord {
              id
              icons {
                id
                responsiveImage(imgixParams: { fit: crop, w: 30, h: 30, auto: format }) {
                  width
                  webpSrcSet
                  title
                  srcSet
                  src
                  sizes
                  height
                  bgColor
                  base64
                  aspectRatio
                  alt
                }
              }
            }
          }
        }
      }
    }
  }
}`;
