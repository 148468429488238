import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { GraphQLClient, ClientContext } from "graphql-hooks";



const client = new GraphQLClient({
  url: "https://graphql.datocms.com/",
  headers: {
    "Authorization": process.env.REACT_APP_GRAPHQL_API_KEY,
  }
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ClientContext.Provider value={client}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ClientContext.Provider>
  </React.StrictMode>
);

