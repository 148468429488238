import styled from 'styled-components';

const NavbarStyled = styled.div`
background-color: black;
opacity: 0.8; // backgroundImage
display: flex;
justify-content: center;
align-items: center;
position: fixed;
height: 30vh;
padding: 0 5rem;
width: 100%;
/* top: 0; */
z-index: 5;

.navbar {
    width: 25%;
    /* height: 100%; */
    /* padding-bottom: 10px; */
    display: flex;
   /*  flex-direction: row; */
    /* justify-content: center; */
    align-items: center;
    /* object-fit: contain; */
}

.title {
text-transform: uppercase;
font-size: 5rem;
font-family: "Tilt Prism";
/* width: 100%;
height: 100%; */
display: flex;
/* justify-content: center; */
align-items: center;
padding-left: 20px;
}

.icon {
width: 35%;
display: flex;
align-items: center;
padding: 2rem;
}

ul {
padding: 0;
display: flex;
justify-content: space-around;
width: 75%;
font-size: 2rem;
list-style: none;
}

a {
text-decoration: none;
color: white;
}

a.active {
    color: #a15656;
}

li {
    position: relative;
    padding: 16px 0;
    /* text-transform: lowercase; */
    transition: color 0.1s,background-color 0.1s,padding 0.2s ease-in;
    color: white;
    font-family: "Hind Madurai";
}

li::before {
    content: '';
    justify-content: center;
    position: absolute;
    bottom: 3px;
    left: 25%;
    height: 3px;
    width: 50%;
    background-color: white;
    transform-origin: right top;
    transform: scale(0, 1);
    transition: color 0.1s,transform 0.2s ease-out;
}

li:hover::before, li:focus::before {
    transform-origin: left top;
    transform: scale(1, 1);
}

@media screen and (max-width: 500px)  {

    height: 30vh;
    padding: 0;
    justify-content: space-around;
    padding-bottom: 5rem;

    .navbar {
        width: 50%;
    }

    ul {
        flex-wrap: wrap;
        font-size: 1rem;
    }

    li {
        padding: 5 0px;
    }

    .title {
        font-size: 3rem;
        padding-left: 5px;
    }

    .icon {
        padding: 0;
    }
}

@media screen and (min-width: 500px)  {

    /* padding: 0 2rem; */

    /* .navbar {
        width: 50%;
    } */

    .icon {
        padding: 0;
    }

    ul {
        flex-wrap: wrap;
        justify-content: space-around;
        padding: 10px 20px;
        /* align-items: center; */
    }

    li {
        padding: 10px
    }

    .title {
        /* font-size: 3rem; */
        padding-left: 5px;
    }
}


`
export default NavbarStyled;