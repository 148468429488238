import React from 'react'
import ImagesCardStyled from './ImagesCardStyled'
import { useQuery } from "graphql-hooks";
import { MY_QUERY } from "../../data/myQuery"
import { Image } from 'react-datocms';
import { Link } from 'react-router-dom';

export default function ImagesCard() {

    const { loading, error, data } = useQuery(MY_QUERY, {
        variables: {
            limit: 10
        }
    });

    if (loading) return "Loading...";
    if (error) return "Something Bad Happened";

    const arrayOfPages = data.allPages
    const arrayOfImages = arrayOfPages[0].sections[2].gridColumns

    return (
        <ImagesCardStyled>
            {arrayOfImages.map((image, id) => {
                const specificImg = image.content.blocks[0].image.responsiveImage
                return <Link key={id} to="/galleri"> <div><Image data={specificImg} /></div> </Link>
            })}
        </ImagesCardStyled>
    )
}
