import React from 'react'
import FooterStyled from "./FooterStyled"
import { useMatch, useResolvedPath, Link } from "react-router-dom"
import { StructuredText } from 'react-datocms'
import { Image } from 'react-datocms'
import img from '../../assets/copyright.png';
import ButtonMailto from '../../components/ButtonMailto'

export default function Footer(data) {

    const arrayOfData = data.data
    const footerContent = arrayOfData.allPages[0].footer[0].gridColumns
    const images = arrayOfData.allPages[0].footer[1].gridColumns

    const resolvedPath = useResolvedPath("/galleri")
    const isActive = useMatch({ path: resolvedPath.pathname, end: true })
    const scrollTo = window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    return (
        <FooterStyled>
            {footerContent.map((content, id) => {
                /* if (content.id === '133057671') {
                    return isActive ?
                        <div className="content" key={id}>
                            <Link to="/terms-and-conditions"><StructuredText onClick={scrollTo} data={content.content} /> </Link>
                        </div> : null
                } else */ {
                    return <div className="contact" key={id}>
                        {/* <Link  to="/contact"><StructuredText data={content.content[0]} /> </Link> */}
                        <StructuredText data={content.content} />

                        <ButtonMailto label="sweets86_@hotmail.com" mailto="mailto:sweets86_@hotmail.com" />
                    </div>
                }
            })}

            <div className="copy">
                <span>
                    <img className="img" alt='icon' src={img} /> &nbsp; 2023 DINwebsite.se
                </span>
            </div>

            <div className="iconDiv">
                {images.map((image) => {
                    const img = image.content.blocks

                    return img.map((content, id) => {
                        const link = content.link.value.document.children
                        const icon = content.image.responsiveImage

                        return link.map((url) => {
                            const iconUrl = url.children[0].url
                            return <div className="icons" key={id}><a className='icon' target={'_blank'} rel="noreferrer" href={iconUrl}><Image data={icon} /> </a></div>
                        })
                    })
                })}
            </div>

            {/* {footerContent.map((content, id) => {
                if (content.id === "133049134" && isActive === true) {
                    return <div key={id}>
                        <Link onClick={scrollTo} to="/terms-and-conditions"><StructuredText data={content.content} /> </Link>
                    </div>
                } else {
                    return <div key={id}>
                        <Link onClick={scrollTo} to="/contact"><StructuredText data={content.content} /> </Link>
                    </div>
                }
            })} */}
            {/* {
                images.map((image, id) => {
                    const img = image.responsiveImage
                    return <div key={id}><Image data={img} /></div>
                })
            } */}
            {/* {footerContent.map((content, id) => {
                if (content.id === '133049133') {
                    return <div key={id}>
                        <Link onClick={scrollTo} to="/contact"><StructuredText data={content.content} /> </Link>
                    </div>
                }
                else if (content.id === '133049134') {
                    return isActive ?
                        <div key={id}>
                            <Link onClick={scrollTo} to="/terms-and-conditions"><StructuredText data={content.content} /> </Link>
                        </div> : null
                }
            })}
            {images.map((image, id) => {
                const img = image.responsiveImage
                return <div key={id}><Image data={img} /></div>
            })} */}

        </FooterStyled >
    )
}
