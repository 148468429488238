import React from 'react'
import HomeStyled from "./HomeStyled"
import ImageBackgroundStyled from "../../components/CSSComponent/ImageBackgroundStyled"
import ContentCard from "../../components/ContentCard"
import VideoAndContent from "../../components/VideoAndContent"
import ImagesCard from "../../components/ImagesCard"
import ListCard from "../../components/ListCard"
import HeadingStyled from "../../components/CSSComponent/HeadingStyled";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "animate.css/animate.min.css";
import { Link } from "react-router-dom";
/* import { useQuery } from "graphql-hooks";
import { MY_QUERY } from "../../data/myQuery" */
import { Image } from "react-datocms"

export default function Home(objectOfArrayOfPages) {
    const arrayOfPages = objectOfArrayOfPages.data

    const title = arrayOfPages.map((pageTitle) => {
        return pageTitle.title
    })

    const backgroundImg = arrayOfPages[0].backgroundImage[0].responsiveImage

    return (
        <HomeStyled>
            <ImageBackgroundStyled>
                <Image className='img' data={backgroundImg} />
            </ImageBackgroundStyled>
            <ContentCard />
            <HeadingStyled>
                <AnimationOnScroll animateIn="animate__fadeInLeftBig">
                    <Link to="/galleri"> <h1>{title[1]}</h1> </Link>
                </AnimationOnScroll>
            </HeadingStyled>
            <ImagesCard />
            <HeadingStyled>
                <AnimationOnScroll animateIn="animate__fadeInLeftBig">
                    <Link to="/foretaget"> <h1>{title[2]}</h1> </Link>
                </AnimationOnScroll>
            </HeadingStyled>
            <VideoAndContent />
            <HeadingStyled>
                <AnimationOnScroll animateIn="animate__fadeInLeftBig">
                    <Link to="/kontakt"> <h1>{title[3]}</h1> </Link>
                </AnimationOnScroll>
            </HeadingStyled>
            <ListCard />
        </HomeStyled >
    )
}
