import styled from "styled-components";

const LayoutStyled = styled.div`
background-color: #1d1b1b;
/* margin-top: 9rem; */ // ta bort margin vid backgroundImage transparent.
width: 100%;
height: auto;
display: flex;
justify-content: center;
`
export default LayoutStyled;