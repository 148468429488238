import React from 'react'
import MaintenanceStyled from './MaintenanceStyled'
import ImageBackgroundStyled from '../../components/CSSComponent/ImageBackgroundStyled'
import { Image, StructuredText } from "react-datocms"
import ButtonMailto from '../../components/ButtonMailto'

export default function Maintenance(objectOfArrayOfPages) {

    const arrayOfPages = objectOfArrayOfPages.data
    const backgroundImg = arrayOfPages[3].backgroundImage[0].responsiveImage
    const thirdPage = arrayOfPages[3].sections
    const image = thirdPage[0].gridColumns[0].content.blocks[0].image
    console.log(image)
    const icons = thirdPage[1]
    const iconsArray = icons.gridColumns[0].content.blocks[0].icons
    const content = thirdPage[2].gridColumns

    return (
        <MaintenanceStyled>
            <ImageBackgroundStyled>
                <Image className='img' data={backgroundImg} />
            </ImageBackgroundStyled>

            <div className='container'>
                <div className='image'> <Image data={image.responsiveImage} /></div>
                <div className='infoContainer'>
                    <div className='icons'>
                        {iconsArray.map((icon, id) => {
                            return <Image className='icon' key={id} data={icon.responsiveImage} />
                        })}
                    </div>
                    <div className='content'>
                        {content.map((info, id) => {
                            if (info.id === '133074537') {
                                return <div className='contact' key={id}><StructuredText data={info.content} />
                                </div>
                            }
                            return <div className='info' key={id}><StructuredText data={info.content} />
                            </div>
                        })}
                        <div className='mail'><ButtonMailto label="sweets86_@hotmail.com" mailto="mailto:sweets86_@hotmail.com" /></div>
                    </div>
                </div>
            </div>
        </MaintenanceStyled>
    )
}
