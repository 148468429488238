import React from 'react'
import ContentCardStyled from './ContentCardStyled'
import { useQuery } from "graphql-hooks";
import { MY_QUERY } from "../../data/myQuery"
import { StructuredText } from "react-datocms";
import ButtonMailto from '../ButtonMailto';

export default function ContentCard() {
    const { loading, error, data } = useQuery(MY_QUERY, {
        variables: {
            limit: 10
        }
    });

    if (loading) return "Loading...";
    if (error) return "Something Bad Happened";

    const arrayOfPages = data.allPages
    const firstSectionOfFirstPage = arrayOfPages[0].sections[0].gridColumns
    const secondSectionOfFirstPage = arrayOfPages[0].sections[1].gridColumns[0].content

    return (
        <ContentCardStyled>
            {firstSectionOfFirstPage.map((content, index) => {
                /* const content = firstSectionOfFirstPageContent.content.value.document.children[0].children[0].value */
                return <div className="content" key={index} ><StructuredText key={index} data={content.content} /></div>
            })}
            <ButtonMailto label="sweets86_@hotmail.com" mailto="mailto:sweets86_@hotmail.com" />
            <div className="galleryContent"><StructuredText data={secondSectionOfFirstPage} /></div>
        </ContentCardStyled>
    )
}
