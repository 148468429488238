import styled from 'styled-components';

const ListCardStyled = styled.div`
height: auto;
width: 75%;
display: flex;
flex-wrap: wrap;
/* flex-direction: column; */
justify-content: center;
/* align-items: center; */
margin: 2rem;
color: white;
/* margin-bottom: 5rem; */

.text{
    font-size: 1.4rem;
    display: flex;
    text-align: center;
    flex-direction: column;
    border: 3px solid grey;
    border-radius: 10px;
    margin-bottom: 10rem;
}

.list {
    display: flex;
    flex-wrap: wrap;
    height: auto;
    width: auto;
    justify-content: center;
    padding: 2rem;
    font-size: 2rem;
    margin-top: 3rem;
    margin-bottom: 10rem;
    text-align: center;
}

p {
    font-weight: bold;
    color: #9f9797;
    font-family: "Josefin Sans";
    padding-right: 2rem;
    padding-left: 2rem;
}

ul {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
}

.mail {
    margin-bottom: 2rem;
}

a {
text-decoration: none;
color: white;
padding: 10px;
margin: 10px;
}

@media screen and (max-width: 500px) {
    font-size: 20px;

    ul  {
        padding: 0;
    }

    li {
        margin: 1rem;
        padding: 0.5rem;
    }

    p {
        font-size: 1.5rem;
        padding: 10px;
    }

    .list {
        padding: 0;
    }
    }

`
export default ListCardStyled;