import styled from 'styled-components';

const HeadingStyled = styled.div`
width: 75%;
height: auto;
color: white;
/* text-transform: lowercase; */
padding: 1rem;

h1 {
    font-family: "Hind Madurai";
    padding: 0 5.5rem;
}

a {
    text-decoration: none;
    color: white;
}

@media screen and (max-width: 500px) {
    width: 100%;
    padding: 0;
}


`
export default HeadingStyled;