import React from 'react'
import TermsAndConditionsStyled from './TermsAndConditionsStyled'

export default function TermsAndConditions() {
    return (
        <TermsAndConditionsStyled>
            <h1>Terms and Conditions</h1>
        </TermsAndConditionsStyled>
    )
}
