import styled from 'styled-components';

const EntertainmentStyled = styled.div`
width: 100%;
height: auto;
display: flex;
flex-wrap: wrap;
justify-content: center;
align-items: center;
margin-bottom: 5rem;
margin-top: 15rem;

.space {
margin: 2rem;
height: 1rem;
}

@media screen and (min-width: 500px)  {
        /* margin-top: 30rem; */
}
`
export default EntertainmentStyled;