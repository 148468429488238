import styled from 'styled-components';

const ContentCardStyled = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    margin: 1rem;
    padding: 0 2rem;
    color: white;
    font-family: "Josefin Sans";

    .content {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        flex-direction: column;
        padding: 0 1rem;
        /* margin: 1rem; */
    }

    .galleryContent {
       /*  display: flex;
        text-align: center; */
        margin-top: 8rem;
    }

    p  {
        font-size: 20px;
        /* color: white; */
        padding: 0.5rem;
        margin: 0;
    }

    h1 {
    color: #9f9797;
    padding: 1.5rem;
    margin-top: 3rem;
    }

    h2 {
    font-size: 1.5rem;
    }

    h3 {
    font-size: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding-top: 1rem;
    padding-bottom: 2rem;
    margin-top: 3rem;
    }

    h4 {
        color: #9f9797;
        font-size: 1.5rem;
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 0;
        padding: 1rem;
    }

    h5 {
        font-size: 1.5rem;
        font-weight: bold;
        margin: 0;
        padding: 0 1rem;
        color: #aa8383;
        border: 1px dotted;
        padding: 1rem;
        display: flex;
        text-align: center;
    }

    @media screen and (max-width: 500px) {

        p {
            font-size: 20px;
        }

        h3 {
            font-size: 3rem;
        }
  }
`
export default ContentCardStyled;