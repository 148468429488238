import styled from 'styled-components';

const HomeStyled = styled.div`
height: auto;
width: 100%;
background-color: #1d1b1b;
display: flex;
flex-direction: column;
align-items: center;

.img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

`
export default HomeStyled;