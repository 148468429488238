import styled from 'styled-components';

const ImagesCardStyled = styled.div`
    width: 75%;
    height: auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    /* margin: 2rem; */
    padding: 0.5rem;
    padding-bottom: 8rem;

    div {
        width: auto;
        height: 15rem;
        border-radius: 10px;
    }

@media screen and (max-width: 500px) {
        flex-wrap: wrap;

        div {
            margin-bottom: 2rem;
        }
}

@media screen and (min-width: 500px)  {
    margin: 0;
    width: 100%;
    div {
        padding: 0 1rem;
    }
}

`
export default ImagesCardStyled;