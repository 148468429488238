import styled from 'styled-components';

const ImageBackgroundStyled = styled.div`
padding-bottom: 50px;
height: 37vh;

@media screen and (max-width: 500px), (min-width: 500px) {
    height: 30vh;
    padding-bottom: 0;
    }

`
export default ImageBackgroundStyled;